import "./App.css";
import { useMemo } from "react";
import Home from "./Home";
import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import Footer from './Footer';
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider, Avatar, } from "@material-ui/core";


import silliImg from './static/silli.png';
import zoidImg from './static/zoid.png';

const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS!
);

const config = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);
const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
    palette: {
        type: 'dark',
    },
    overrides: {
        MuiButtonBase: {
            root: {
                justifyContent: 'flex-start',
            },
        },
        MuiButton: {
            root: {
                textTransform: undefined,
                padding: '12px 16px',
            },
            startIcon: {
                marginRight: 8,
            },
            endIcon: {
                marginLeft: 8,
            },
        },
    },
});



const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
        getPhantomWallet(),
        getSlopeWallet(),
        getSolflareWallet(),
        getSolletWallet({ network }),
        getSolletExtensionWallet({ network })
    ],
    []
  );
  
  return (
      <ThemeProvider theme={theme}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect={true}>
            <WalletDialogProvider>
                <Home
                    candyMachineId={candyMachineId}
                    config={config}
                    connection={connection}
                    startDate={startDateSeed}
                    treasury={treasury}
                    txTimeout={txTimeout}
                  />
                  <div style={{   display: 'flex',
                                  flexDirection: "column",
                                  alignItems: 'center',
                                  justifyContent: 'center',}}>
                    <div className="about_shatter_div" >
                      <h1> About Shatter </h1>
The fabric of reality has been shattered. The laws of physics are no more. <br/> Nightmarish monsters and literal gods have slipped through the cracks. All hope is lost. <br/>  At least we got some pretty pictures out of it. <br/> <br/>

Shatter is a neural network generated NFT art collection.<br/> For months we have been creating these unique images from multiple computers and using generative adversarial networks(GAN) and contrastive language image pre-training(CLIP) to generate these beautiful NFT's in our unique style. <br/>
<br/> <br/>


Using our incredible coding skills we have turned these images into Solana NFT's that will be minted in the near future. Please more about our project, check out our art and come join us on <a href="https://discord.gg/KRjNy9GgbA" target="_blank"> Discord</a> if you like it!
<br/>
<h1> MINTING DECEMBER 5TH RIGHT HERE</h1>
                    </div>
                    <div className="roadmap_div" >
                      <h1> Roadmap </h1>
                        <div><h3>1.</h3><h4>Build community</h4></div> 
                        <div><h3>2.</h3><h4> Minting round 1 on December 5th, 635 supply, price 0.35 SOL</h4></div>
                        <div><h3>3. </h3><h4>Get listed on all the major marketplaces (already approved on Digital Eyes)</h4></div>
                        <div ><h3>4. </h3><h4>Get listed on howrare.is</h4></div>
                        <div ><h3>5. </h3><h4>Random airdrop to holders 1 week after round 1 mint</h4></div>
                        <div><h3>6. </h3><h4>If all goes well will have another round of minting, if not we'll burn the rest of the supply.</h4></div>
                        <div><h3>7.</h3><h4>Do whatever the community wants us to do</h4></div>
                    </div>
                    <div className="faq_div">
                    <h1> FAQ </h1>
                    <b>When will you be minting and where?</b> <br/>
                    Minting round 1 is sunday December 5th. Minting will be on Solana
                    <br/><br/>

                    <b>Who are you guys?</b><br/>
                    Friends since way back with  mutual interest in blockchain technology, crypto, modern art and cracking code.
                    Both computer scientist who have been in the crypto space for a while.<br/><br/>

                    <b>Why are you doing this?</b><br/>
                    We started making neural network generated art for fun and just kinda fell in love with it. We've had such a good time and the artwork turned out so well we decided to turn it into an NFT collection.<br/> <br/>

                    <b>Why should I care about your project?</b><br/>
                    Just check out some of our art. If you like it, stay for a while, you won't regret it. If not, no hard feelings.<br/><br/>

                    <b>Where will you be listed?</b><br/>
                    Wherever we can. We have already been approved on Digital Eyes<br/><br/>

                    
                    <b>Where can I see more of your artwork?</b><br/>
                    You can check out more of our artwork on the #sneak-peak channel of our <a href="https://discord.gg/KRjNy9GgbA" target="_blank"> Discord</a>.<br/>
                    We also post quite a bit on <a href="https://twitter.com/ShatterNFT" target="_blank">Twitter </a>.
                    <br/><br/>

                    <b>Why does your website look so old-school?</b><br/>
                    Because we both suck at React/CSS.
                    <br/><br/>

                    <b>What does it mean to be an EarlyBird?</b><br/>
                    The first 100 people to join our Discord server get the early bird discord role. They will be eligible for airdrops and other perks.<br/><br/>

                    <b>Are you planning on releasing any UpDog?</b><br/>
                    There might be some UpDog in the future.<br/><br/>

                    <b>What is your mint price gonna be?</b><br/>
                    Our mint price is 0.35 SOL.<br/><br/>

                    <b>How do I get a Solana wallet?</b><br/>
                    We recommend the <a href="https://phantom.app/">Phantom</a> browser wallet extension!<br/><br/>

                    <b>What do you mean by burning the remaining NFT's after the deadline of round 1?</b><br/>
                    Our goal is to make our NFT collection accessible to everyone to mint, but not forever!
                    by burning the remaining NFT's(if there will be any) we make all minted Shatter NFT's 
                    more scarce and more valuable for the owner.
                    <br/><br/>

                    </div>
                    <div className="team_div">
                      <h1> The team </h1>
                    <div className="team_div_inside">
                    <div className="zoid">
                          <div> <Avatar variant={"circle"} alt="zoid" src={zoidImg} style={{
                                                                          objectFit: "contain",
                                                                          height: 120,
                                                                          width: 130,
                                                                          padding: 2,}}/></div>
                          <div> <h2> Zoid </h2></div>
                          <div> Software Engineer. <br/> Currently working in the crypto space. <br/> To ape or not to ape.</div>
                        </div>

                        <div className="silli">
                          <div>                 <Avatar variant={"circle"} alt="Silli" src={silliImg} style={{
                                                                          objectFit: "contain",
                                                                          height: 120,
                                                                          width: 130,
                                                                          padding: 2,}}/>
                          </div>
                          <div><h2> ShatterSilli</h2></div>
                          <div> Computer scientist from Iceland.<br/> Full-stack developer.<br/> Dog lover and crypto enthusiast. </div>
                        </div>
                        </div>
                    </div>
                  </div>
              <Footer />
            </WalletDialogProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ThemeProvider>
  );
};

export default App;

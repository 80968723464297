import {
    AppBar,
    Toolbar,
    makeStyles,
    Button,
    Box,
    Avatar,
    IconButton,
} from "@material-ui/core";
import img from './static/logo.png';
import styled from "styled-components";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import { Link as RouterLink } from "react-router-dom";
import { shortenAddress } from "./candy-machine";
import solanaImg from './static/solana-sol-logo.png';
import { AccountBalanceWallet } from '@material-ui/icons';

import { Link } from "react-scroll";
const ConnectButton = styled(WalletDialogButton)``;

const isLive = true;

const headersData = [
    {
      label: "ABOUT SHATTER",
      href: "about_shatter_div",
      hasComponent: false,
    },
    {
      label: "ROADMAP",
      href: "roadmap_div",
      hasComponent: false,
    },
    {
      label: "FAQ",
      href: "faq_div",
      hasComponent: false,
    },
    {
      label: "THE TEAM",
      href: "team_div",
      hasComponent: false,
    },
];
const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: "#2E3E64",
        paddingRight: "79px",
        paddingLeft: "118px",
      },
    logo: {
      fontFamily: "Work Sans, sans-serif",
      fontWeight: 600,
      color: "#FFFEFE",
      textAlign: "left",
    },
    menuButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        size: "18px",
        marginLeft: "38px",
     },
     toolbar: {
        display: "flex",
        justifyContent: "space-between",
      },
      flexContainer: {
        display: "flex",
        justifyContent: "space-between",
      },
}));
  
export default function Header(props: any) {
    const { header, menuButton, flexContainer} = useStyles();


    const displayDesktop = () => {
      return (
        <Toolbar className={flexContainer}>
            {shatterLogo}
            <div>{getMenuButtons()}</div>
            {
              !props.wallet ?
                        <ConnectButton disabled={!isLive} className="connect_to_wallet_button">CONNECT TO WALLET </ConnectButton>
                        :
                        <Box component="span" sx={{ p: 1 }}>
                          <span>
                            <div style={{display: "flex", flexDirection: "row", padding: 1,paddingRight: 5,}}>
                              <AccountBalanceWallet style={{display: 'flex'}} />{shortenAddress(props.wallet.publicKey.toBase58() || "")}
                            </div>
                            <div style={{display: 'flex', flexWrap: 'nowrap'}}>
                              <Avatar variant={"square"} alt="Solana logo" src={solanaImg} style={{
                                                                                        height: 16,
                                                                                        width: 19,
                                                                                        padding: 2,
                                                                                        paddingRight: 5,
                            }} /> {props.balance}
                            </div>
                          </span>
                        </Box>
            }
        </Toolbar>
      );
    };

    const shatterLogo = (
      <IconButton onClick={() => { window.scrollTo(0, 0); }}>
      <Avatar variant={"square"} alt="The image" src={img} style={{
        objectFit: "contain",
        height: 75,
        width: 88,
        padding: 2,
      }} />
      </IconButton>
    );
                       
    const getMenuButtons = () => {
      return headersData.map(({ label, href, hasComponent }) => {
        return (
          <Link to={href} spy={true} smooth={true} offset={0} duration={500}>
            <Button
                {...{
                key: label,
                color: "inherit",
                to: href,
                component: !hasComponent ? RouterLink : ConnectButton,
                className: menuButton
                }}
            >
                {label}
            </Button>
            </Link>
          );
      });
    };
    return (
      <header  className="header_div">
        <AppBar position="static" className={header}>{displayDesktop()}</AppBar>
      </header>
    );
}
import styled from 'styled-components';
import React from "react";

import {
  Avatar,
  IconButton,
} from "@material-ui/core";

import twitterImg from './static/Twitter_bird_logo_2012.png';
import discordImg from './static/discord-logo.png';
import solanaImg from './static/solana-sol-logo.png';
import metaplexImg from './static/metaplex-logo.png';
import tensorflowImg from './static/tensorflow-logo3.png';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    /* background: red; */
`

export const Box = styled.div`
padding: 5px 5px;
background-color: #222222;
background: #11;
position: sticky;
bottom: 0;
width: 100%;

 
@media (max-width: 1000px) {
  position: initial;
}
`;

const Footer = () => {
  return (
    <Box>
      <Container>
        <div className="footer_container">
          <div>
          </div>
          <div className="powered_by_div">
                          <p className="powered_by_p">
                          Powered by:</p>
                          <IconButton href="https://solana.com/" target="_blank" rel="noopener noreferrer"> 
                            <Avatar variant={"square"} alt="Solana logo" src={solanaImg} className="powered_by_logos"/>       
                            </IconButton> 
                            <IconButton href="https://metaplex.com/" target="_blank" rel="noopener noreferrer">               
                            <Avatar variant={"square"} alt="Metaplex logo" src={metaplexImg} className="powered_by_logos" />
                              </IconButton> 
                              <IconButton href="https://www.tensorflow.org/" target="_blank" rel="noopener noreferrer"> 
                              <Avatar variant={"square"} alt="Tensorflow logo" src={tensorflowImg} className="powered_by_logos" />
                                </IconButton> 
                              <p className="powered_by_p"> &reg; {new Date().getFullYear()} </p>

          </div>
          <div className="social_media_div">
                <IconButton href="https://twitter.com/ShatterNFT" target="_blank" rel="noopener noreferrer">
                <Avatar variant={"square"} alt="Twitter logo" src={twitterImg} style={{
                                                                            objectFit: "contain",
                                                                            height: 40,
                                                                            width: 45,
                                                                            padding: 2,
                }} />
                </IconButton>
                <IconButton href="https://discord.gg/KRjNy9GgbA" target="_blank" rel="noopener noreferrer">
                <Avatar variant={"square"} alt="Discord logo" src={discordImg} style={{
                                                                          objectFit: "contain",
                                                                          height: 40,
                                                                          width: 45,
                                                                          padding: 2,
                }} />
                </IconButton>
          </div>
        </div>

      </Container>
    </Box>
  );
};
export default Footer;